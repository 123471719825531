<template>
  <div :class="`collapse ${state}`" :id="`collapse-${collapseID}`">
    <div class="collapse-header" @click="toggle" v-if="header !== 'hidden'">
      <div class="collapse-header-left">
        <iconic :name="icon" />
        <p class="title" v-html="title"></p>
      </div>
      <div class="collapse-header-right">
        <iconic class="right-icon" name="chevronDown" />
      </div>
    </div>
    <div class="collapse-content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  props: ["header", "icon", "title", "settings"],
  data: function () {
    return {
      state: null,
      collapseID: this.$global.fastID(),
      timing: null,
      timeIn: 250,
      timeOut: 20,
      mySettings: { ...this.settings },
    };
  },
  methods: {
    elements: function (el) {
      const elmns = {
        collapse: document.querySelector(`#collapse-${this.collapseID}`),
        content: document.querySelector(`#collapse-${this.collapseID} .collapse-content`),
        custom: document.querySelector(el),
      };
      return elmns[el] || elmns["custom"];
    },
    open: function () {
      clearTimeout(this.timing);
      const collapse = this.elements("collapse");
      const content = this.elements("content");
      const contentHeight = content && content.scrollHeight;
      this.state = "open";
      content.style.height = contentHeight + "px";
      this.timing = setTimeout(() => {
        content.style.height = "auto";
        this.mySettings?.scrollIntoView && collapse.scrollIntoView({ behavior: "smooth" });
      }, this.timeIn);
      this.$emit(this.state);
    },
    close: function () {
      clearTimeout(this.timing);
      const content = this.elements("content");
      const contentHeight = content && content.scrollHeight;
      this.state = "close";
      content.style.height = contentHeight + "px";
      this.timing = setTimeout(() => {
        content.style.height = "0px";
      }, this.timeOut);
      this.$emit(this.state);
    },
    toggle: function () {
      const collapse = this.elements("collapse");
      if (this.state === "open") {
        this.close();
      } else if (collapse) {
        this.open();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.collapse {
  &-header {
    @include Flex(inherit, space-between, center);
    user-select: none;
    cursor: pointer;
    .iconic {
      font-size: 150%;
      color: $sec_color;
    }
    &-left,
    &-right {
      @include Flex(inherit, center, center);
    }
    &-left {
      .iconic {
        margin: 0 $mpadding/2 0 0;
      }
    }
  }
  &-content {
    overflow: hidden;
    height: 0;
    transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.3s;
    > * {
      transition: all cubic-bezier(0.075, 0.82, 0.165, 1) 0.25s;
      opacity: 0;
      transform: translateY(-100%);
    }
  }
  &.open {
    .right-icon {
      transform: rotate(180deg);
    }
    .collapse-content {
      > * {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }
}
</style>
